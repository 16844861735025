<template>
  <el-dialog
    title="生成采购订单"
    width="1500px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :checkbox-config="tableConfig.checkboxConfig"
        :columns="tableColumn"
        :data="selfDetail.list"
        :show-footer="!!selfDetail.list.length"
      >
        <template #edit_cai_gou_stock_counts="{ row }">
          <el-input
            v-model="row.cai_gou_stock_counts"
            type="number"
            size="mini"
            clearable
            @blur="countsBlur(row)"
          />
        </template>
        <template #edit_cai_gou_stock_price="{ row }">
          <el-input
            v-model="row.cai_gou_stock_price"
            type="number"
            size="mini"
            clearable
            @blur="countsBlur(row)"
          />
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush(false)">提交</el-button>
      <el-button size="small" type="primary" @click="popupPush(true)">提交并打印</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {
          cai_gou_bill_date: this.$XEUtils.toDateString(this.$XEUtils.now(), 'yyyy-MM-dd'), // 采购日期
          cai_gou_code: '', // 采购单号
          supplier_company_name: '', // 供应商-name
          supplier_company_uuid: '', // 供应商-uuid
          is_cai_gou_kai_piao: false, // 是否开票
          cai_gou_fu_kuan_fang_shi: '账期', // 付款方式
          cai_gou_jiao_huo_ri_qi: '', // 交货日期
          cai_gou_ti_huo_fang_shi: '供应商送货', // 提货方式
          cai_gou_remark: '', // 采购备注
          send_cangku: false // 发送仓库部门
          // send_shengchan: false // 发送生产部门
        },
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'date',
          title: '采购日期',
          field: 'cai_gou_bill_date',
          required: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '采购单号',
          field: 'cai_gou_code',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'select',
          title: '供应商',
          field: 'supplier_company_uuid',
          required: true,
          options: []
        },
        {
          type: 'select',
          title: '是否开票',
          field: 'is_cai_gou_kai_piao',
          options: [
            {
              key: '是',
              val: true
            },
            {
              key: '否',
              val: false
            }
          ]
        },
        {
          type: 'select',
          title: '付款方式',
          field: 'cai_gou_fu_kuan_fang_shi',
          options: [
            {
              key: '账期',
              val: '账期'
            },
            {
              key: '订金',
              val: '订金'
            },
            {
              key: '全款',
              val: '全款'
            }
          ]
        },
        {
          type: 'date',
          title: '交货日期',
          field: 'cai_gou_jiao_huo_ri_qi',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'select',
          title: '提货方式',
          field: 'cai_gou_ti_huo_fang_shi',
          options: [
            {
              key: '供应商送货',
              val: '供应商送货'
            },
            {
              key: '自提',
              val: '自提'
            }
          ]
        },
        {
          type: 'text',
          title: '采购备注',
          field: 'cai_gou_remark'
        },
        {
          type: 'checkbox',
          title: '仓库部门',
          field: 'send_cangku',
          placeholder: '发送'
        }
        // {
        //   type: 'checkbox',
        //   title: '生产部门',
        //   field: 'send_shengchan',
        //   placeholder: '发送'
        // }
      ],
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          title: '序号',
          type: 'seq',
          width: 50
        },
        {
          title: '采购申请单号',
          field: 'shen_gou_code',
          width: 104
        },
        {
          title: '销售单号',
          field: 'bill_code',
          width: 104
        },
        {
          title: '商品类型',
          field: 'type_name',
          width: 60
        },
        {
          title: '业务员',
          field: 'saler_name',
          width: 60
        },
        {
          title: '建议供应商',
          field: 'jian_yi_supplier_name',
          width: 120
        },
        {
          title: '品名/工艺',
          field: 'craft',
          width: 120
        },
        {
          title: '材质',
          field: 'texture',
          width: 60
        },
        {
          title: '规格',
          field: 'specification',
          width: 120
        },
        {
          title: '单位',
          field: 'unit',
          width: 60
        },
        {
          title: '申购数量',
          field: 'shen_gou_stock_counts',
          width: 60
        },
        {
          title: '已采购数量',
          field: 'sum_cai_gou_stock_counts',
          width: 70
        },
        {
          title: '本次采购数量',
          field: 'cai_gou_stock_counts',
          width: 100,
          slots: { edit: 'edit_cai_gou_stock_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '单价(元)',
          field: 'shen_gou_stock_price',
          width: 80
        },
        {
          title: '采购单价(元)',
          field: 'cai_gou_stock_price',
          width: 100,
          slots: { edit: 'edit_cai_gou_stock_price' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '采购金额(元)',
          field: 'cai_gou_stock_amount',
          width: 80
        },
        {
          title: '商品备注',
          field: 'cai_gou_remark_stock'
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        },
        checkboxConfig: {
          checkMethod: ({ row }) => {
            return Number(row.shen_gou_stock_counts) > Number(row.sum_cai_gou_stock_counts)
          },
          visibleMethod({ row }) {
            return Number(row.shen_gou_stock_counts) > Number(row.sum_cai_gou_stock_counts)
          }
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
    this.getSupplierList()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      let ids = this.list.map((item) => item.shen_gou_stock_id).join(',')
      this.$api({
        method: 'get',
        url: '/admin/gcvip/caigou/buildCaiGouBill',
        params: {
          shen_gou_stock_ids: ids
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let list = this.$util.deepCopy(res.data.list)
          list.forEach((item) => {
            item.cai_gou_remark_stock = item.shen_gou_remark_stock
            // 初始化数量、单价、金额、选中状态
            if (!Number(item.cai_gou_stock_counts)) {
              item.cai_gou_stock_counts = this.$XEUtils.subtract(
                item.shen_gou_stock_counts,
                item.sum_cai_gou_stock_counts
              )
            }
            item.cai_gou_stock_price = item.shen_gou_stock_price
            item.sum_cai_gou_stock_counts = item.sum_cai_gou_stock_counts || 0
            item.cai_gou_stock_amount = this.$XEUtils.multiply(
              item.cai_gou_stock_price,
              item.cai_gou_stock_counts
            )
          })
          this.selfDetail.list = this.$util.deepCopy(list)
          this.selfDetail.list.forEach((item) => {
            // 申购数量 > 已采购数量
            if (Number(item.shen_gou_stock_counts) > Number(item.sum_cai_gou_stock_counts)) {
              this.$nextTick(() => {
                this.$refs.refTable.setCheckboxRow([item], true) // 默认选中
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取-供应商列表
    getSupplierList() {
      this.$api({
        method: 'post',
        url: '/admin/supplier',
        data: {
          currentPage: 1,
          pageSize: this.$constant.pageMax
        }
      })
        .then((res) => {
          if (res.status === 200 && res.data.state === 'ok') {
            let result = res.data.page.list.map((item) => {
              return {
                key: item.supplier_name,
                val: item.company_uuid
              }
            })
            this.formColumn.forEach((item) => {
              if (item.field === 'supplier_company_uuid') {
                item.options = result
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 需求数量-失焦
    countsBlur(row) {
      row.cai_gou_stock_amount = this.$XEUtils.multiply(
        row.cai_gou_stock_price,
        row.cai_gou_stock_counts
      )
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 获取-当前复选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush(isPushAndPrint) {
      if (!this.selfDetail.formData.cai_gou_bill_date) {
        this.$message.error('请选择采购日期')
        return
      }
      if (!this.selfDetail.formData.supplier_company_uuid) {
        this.$message.error('请选择供应商')
        return
      } else {
        this.formColumn.forEach((item) => {
          if (item.field === 'supplier_company_uuid') {
            item.options.forEach((itemOption) => {
              if (itemOption.val === this.selfDetail.formData.supplier_company_uuid) {
                // 根据uuid，获取供应商名称
                this.selfDetail.formData.supplier_company_name = itemOption.key
              }
            })
          }
        })
      }
      let rows = this.getCheckboxRecords()
      if (rows.length) {
        // 判断选中行本次采购数量是否都>0
        let isPass = rows.every((item) => !!Number(item.cai_gou_stock_counts))
        if (!isPass) {
          this.$message.error('选中行的本次采购数量必须>0')
          return
        }
      } else {
        this.itemChoosePrompt()
      }
      let params = {
        formData: {
          ...this.selfDetail.formData
        },
        list: [...rows]
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/caigou/saveOrUpdate',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')

            // 项-提交并打印
            if (isPushAndPrint) {
              this.popupPrint(res.data.code)
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 项-打印
    popupPrint(code) {
      window.open(`/purchase/order-purchase/print?code=${code}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped></style>
